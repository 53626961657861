import * as React from "react";
import Seo from "src/components/seo";
import Layout from "src/components/layout";
import {StaticImage} from "gatsby-plugin-image";
import GooglePlayButton from "src/components/common/google_play_button";
import AppleStoreButton from "src/components/common/apple_store_button";
import {AkamaruSeries} from "src/components/akamaru/series";
import {AkamaruAuthor} from "src/components/akamaru/author";
import {BackHome} from "src/components/common/back_home";
import {graphql, Link} from "gatsby";
import "src/styles/products/main.scss";

const AkamaruGameList = () => {

  return (
    <>
      <section>
        <h2>いろいろなゲームで遊ぶことができます</h2>

        <p>おこさまの年齢や興味に合わせて、さまざまなゲームを用意しています。</p>
        <div className="gameList">
          <ul>
            <li>
              <Link to="/products/akamaru/1">
                <StaticImage
                  className="gameList__card"
                  src="../../images/akamaru/cards/jp/home_game_amount_card.png"
                  alt="たくさんはどっち" />
              </Link>
            </li>

            <li>
              <Link to="/products/akamaru/2">
                <StaticImage
                  className="gameList__card"
                  src="../../images/akamaru/cards/jp/home_game_want_color_card.png"
                  alt="ちがうのどれかな？ いろ" />
              </Link>
            </li>

            <li>
              <Link to="/products/akamaru/3">
                <StaticImage
                  className="gameList__card"
                  src="../../images/akamaru/cards/jp/home_game_puzzle_shape_card.png"
                  alt="パズルあそび かたち" />
              </Link>
            </li>

            <li>
              <Link to="/products/akamaru/4">
                <StaticImage
                  className="gameList__card"
                  src="../../images/akamaru/cards/jp/home_game_memory_shape_card.png"
                  alt="メモリーゲーム かたち" />
              </Link>
            </li>
          </ul>
        </div>

        <p className="text-center">

          <Link className="btn btn-secondary" to="/products/akamaru-games">
            その他のゲームはこちら
          </Link>
        </p>

      </section>
    </>
  );
}

const AkamaruAppDrillFeatures = () => {
  return (
    <>
      <section>
        <h2>お子さまの成長をサポートする知育ドリル</h2>

        <StaticImage
          src="../../images/akamaru/drill/drill_features.png"
          alt="あかまるアプリ ドリルイメージ" />
        <p></p>
        <p>カラフルで認識しやすい色や形、かわいいキャラクターを使って、楽しみながら「色」・「図形」・「数字」・「英語（アルファベット）」の認識を育むドリルモードを搭載しています。</p>
        <p>1つのドリルに75問の問題が用意されているので、じっくり遊ぶことができます。 ドリルをクリアするとかわいいスタンプをゲット！がんばった成果を称えることで、さらなるモチベーションアップにつながります。        </p>
      </section>
    </>
  )
}

const AkamaruAppFeatures = () => {
  return (
    <>
      <section>
        <h2>知育ゲーム「タッチであそぼ！あかまるどれかな？」の特徴</h2>

        <h3>「いろ」や「かたち」の認識力を身につけられる！</h3>
        <p>大人気絵本シリーズのキャラクターである「あかまる」と幼児のうちに身につけておきたい認識力を育むことができる知育ゲームになっています！楽しいゲームで遊びながら、自分の頭で考える力が身につきます。</p>

        <h3>音声付き！1歳から遊べるシンプルな知育ゲーム</h3>
        <p>出題はどれも「あかはどれかな？」「まるはどれかな？」といったシンプルなもの。2歳3歳…といった幼いお子さんでも、すぐに遊び方がわかり、1人でも取り組みたくなります。</p>
        <p>出題や答えはすべてかわいいこどもの声の音声で流れるので、まだ文字を読めないお子さまでも遊べます。家事などで忙しい時間帯に、お子さまの遊び道具としてお使いいただけます。</p>

        <p>もちろんおうちの方が出題文を読んでお子さまが答えるなど、ご家族で一緒に楽しみながら、親子のコミュニケーションをとる道具としてもご活用いただけます。</p>

        <h3>時間制限があるから時間を決めて遊べます</h3>
        <p>1回のゲームには制限時間が設けられています。「今日は1回だけ」「次の1回で終わりにしようね」などとルールを決めて遊ぶことができます。</p>

        <h3>「あかまる」絵本発の知育ゲーム。安心して遊べます</h3>
        <p>「あかまる」シリーズ（作・しみずだいすけ）は、ポプラ社より発売されている累計26万部を突破した人気の絵本シリーズです。「あかはどれかな？」「まるはどれかな？」と問いかけながら読み進められ、自然とこどもたちの考える力をはぐくむシリーズとして、広く支持されています。本アプリもそのエッセンスを存分に取り入れたものとなっています。</p>

      </section>
    </>
  )
}

const AkamaruRecommendedUsage = () => {
  return (
    <>
      <section>
        <h2>知育ゲーム「タッチであそぼ！あかまるどれかな？」のおすすめの使い方</h2>

        <h3>朝のちょっと忙しい時間に</h3>
        <p>
          育児期は、お子さまの仕度とパパ・ママの準備とでバタバタしてしまいがち。そんな時間にこのアプリをお子さまに取り組んでいただいたらいかがでしょうか？少しの間、お子さんが自分でひとり遊びする時間になるはずです。
        </p>

        <h3>おでかけの待ち時間に</h3>
        <p>
          楽しいお出かけも、待ち時間をどう過ごすかは悩みの種です。レストランの待ち時間、次の電車が来るまでの時間…お子さんがぐずってしまいそうなときに、一緒にこのアプリで遊んでみることをおすすめします！無事に待ち時間を過ごせたら、「ちゃんと待ってられたね」と声をかけてあげれば、お子さんも喜ぶこと間違いありません！
        </p>
      </section>
    </>
  )
}

const AkamaruDownloadButtons = () => {
  return (
    <section>
      <p className="text-center download-buttons">
        <AppleStoreButton/>
        <GooglePlayButton/>
      </p>
    </section>
  )
}

const Akamaru = () => {
  const title = "タッチであそぼ！あかまるどれかな？"
  const description = "「タッチであそぼ！あかまるどれかな？」は、赤ちゃん・幼児を中心に、親子で楽しみながら色や形といった認識をはぐくむためのアプリです。自分で考えて、自らタッチして遊ぶことで、自分の頭で考える力を養うことができます。"

  return (
    <Layout>
      <Seo title={title} description={description} pagePath={"/product/akamaru"}/>
      <article className="container article-container">
        <header className="article-header">
          <h1>知育ゲーム「タッチであそぼ！あかまるどれかな？」</h1>
        </header>

        <section>
          <p>
            「タッチであそぼ！あかまるどれかな？」は、親子で遊んで思わずにっこり！楽しみながら色や形などの認識力を育てることができる1歳からの知育アプリです。
          </p>
          <p>
            自分で考えて、自らタッチして遊ぶうちに、自然と「自分の頭で考える力」を養うことができます。また、おうちの方がお子さんと一緒に遊びながら、ゲームにあわせて声かけをしていくことで、絵本の読み聞かせをするように、お子さまとのコミュニケーションを深めることもできます
          </p>

          <StaticImage
            src={"../../images/akamaru/feature-graphic.png"}
            alt="あかまるアプリの紹介"
          />
        </section>
        <AkamaruDownloadButtons/>

        <AkamaruGameList />

        <AkamaruAppDrillFeatures />

        <AkamaruAppFeatures/>
        <AkamaruDownloadButtons/>

        <AkamaruRecommendedUsage/>
        <AkamaruDownloadButtons/>

        <AkamaruSeries/>
        <AkamaruAuthor/>
        <AkamaruDownloadButtons/>
      </article>

      <BackHome />
    </Layout>
  )
}

export default Akamaru

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
